import React, {
  useEffect, useRef, useState, useCallback, useMemo,
} from 'react';
// import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
// import { devicesActions } from '../store';
import DeviceRow from './DeviceRow';
// import { fetchMsgPack } from '../common/util/utils';

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: '100%',
  },
  listInner: {
    position: 'relative',
    margin: theme.spacing(1.5, 0),
  },
}));

const DeviceList = ({ devices }) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const listInnerEl = useRef(null);

  useEffect(() => {
    if (listInnerEl.current) {
      listInnerEl.current.className = classes.listInner;
    }
  }, [listInnerEl, classes.listInner]);

  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // useEffect(() => {
  //   const fetchDevices = async () => {
  //     const devices = await fetchMsgPack('/api/devices');
  //     dispatch(devicesActions.update(devices));
  //   };

  //   fetchDevices();
  // }, [dispatch]);

  const rowRenderer = useCallback(({ index, style, data }) => (
    <DeviceRow data={data} index={index} style={style} />
  ), []);

  const memoizedDevices = useMemo(() => devices, [devices]);

  return (
    <AutoSizer className={classes.list}>
      {({ height, width }) => (
        <FixedSizeList
          width={width}
          height={height}
          itemCount={memoizedDevices.length}
          itemData={memoizedDevices}
          itemSize={72}
          overscanCount={10}
          innerRef={listInnerEl}
        >
          {rowRenderer}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
};

export default React.memo(DeviceList);
