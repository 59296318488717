import { distanceFromMeters } from '../common/util/converter';

export const isLast30DaysMatching = (device, minTravel, maxTravel, distanceUnit) => {
  if (device.disabled === true) {
    return false;
  }
  if (maxTravel === 0) {
    return distanceFromMeters(device.thirtyDayDistance, distanceUnit) >= minTravel;
  }
  return distanceFromMeters(device.thirtyDayDistance, distanceUnit) >= minTravel && distanceFromMeters(device.thirtyDayDistance, distanceUnit) < maxTravel;
};

export const isConditionMatching = (device, condition) => {
  if (device.disabled === true) {
    return false;
  }
  switch (condition) {
    case 'recovery':
      return device.recovery;
    case 'abandoned':
      return device.abandonTime != null;
    case 'impounded':
      return device.impoundTime != null;
    case 'notinstalled':
      return device.installTime === null;
    default:
      return false;
  }
};
